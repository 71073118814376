let awsRegion = 'eu-west-1';

export default {
 api_urls: {
   reporting: 'https://0pcfkc7924.execute-api.eu-west-1.amazonaws.com/api',
   geojson: 'https://klr2v39gqh.execute-api.eu-west-1.amazonaws.com/api',
   overlays: 'https://b1a3l948y5.execute-api.eu-west-1.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.europe.forwoodsafety.com',
 forwood_id_url: 'https://id.europe.forwoodsafety.com?redirect_uri=https://geoeditor.europe.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.europe.forwoodsafety.com',
 Auth: {
   userPoolId: 'eu-west-1_OFCFdW11F',
   userPoolWebClientId: '2f5e0qnr8speal0jl2oo9rcc9l',
   cookieStorage: {
     domain: '.europe.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'prod'
};
